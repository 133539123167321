/***********************************************************************************************************************
 *
 * VUEX ACTIONS
 *
 * ASYNC
 *
 **********************************************************************************************************************/
import axios from "axios";
import router from "../router";

const ACTIONS = {

    /*
     * SET CUSTOMER NUMBER
     * @param {Object} ctx - Vuex $store context
     * @param String payload
     */
    SET_CUSTOMERNO: function(ctx, payload) {
        ctx.commit("SET_CUSTOMER_NUMBER", payload);
    },

    /*
     * SET METER NUMBER
     * @param {Object} ctx - Vuex $store context
     * @param String payload
     */
    SET_METERNO: function(ctx, payload) {
        ctx.commit("SET_METER_NUMBER", payload);
    },

    /*
     * DO AUTHENTFICATION
     * @param {Object} ctx - Vuex $store context
     * @param {Object} payload
     * @param {String} payload.customerNo
     * @param {String} payload.meterNo
     */
    DO_AUTH: function(ctx, payload) {
        ctx.commit("SET_REQUESTING", true);
        ctx.commit("RESET_ERRORS");
        axios
            .post("/api/auth", payload)
            // we have either found a customer or not
            .then(response => {
                if (response.status === 200) {
                    ctx.commit("SET_REQUESTING", false);
                    if (response.data.error === "1") {
                        ctx.commit(
                            "SET_AUTH_ERROR",
                            "😱 Leider konnten wir für Ihre Eingabe keinen Kunden ermitteln.<br />" +
                            "Bitte überprüfen Sie Ihre Kunden- und Zählernummer."
                        );
                        ctx.commit("SET_SECTION_STATUS", {section: "auth", status: "error"});
                        ctx.commit("SET_SECTION_STATUS", {section: "meterReading", status: "locked"});
                    } else {
                        // good to go.
                        ctx.commit("RESET"); // fresh new state
                        ctx.commit("SET_CUSTOMER_NUMBER", response.data.customerNo);
                        ctx.commit("SET_METER_NUMBER", response.data.meterNo);
                        ctx.commit("SET_CUSTOMER_DATA", response.data);
                        ctx.commit("SET_SECTION_STATUS", {section: "auth", status: "ok"});
                        ctx.commit("SET_SECTION_STATUS", {section: "meterReading", status: ""});
                        router.push({name: "meterReading"});
                    }
                }
            })
            // something went wrong on Laravel's side.
            .catch(error => {
                ctx.commit("SET_REQUESTING", false);
                console.log(error);
                console.error(`${error.response.status} ${error.response.statusText}`);
                switch(error.response.status) {
                    case 429:
                        ctx.commit(
                            "SET_SERVER_ERROR",
                            "😡 Zuviele Anfragen.<br />" +
                            "Bitte versuchen Sie es in Kürze erneut."
                        );
                        break;
                    default: // mainly 500s. server can't reach DB, tables gone etc.
                        ctx.commit(
                            "SET_SERVER_ERROR",
                            "😓 Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten.<br />" +
                            "Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Kundenservice."
                        );
                        break;
                }
            });
    },


    /*
     * LOGOUT
     * @param {Object} ctx - Vuex $store context
     */
    LOGOUT: function(ctx) {
        ctx.commit("RESET"); // reset state
        router.push({name: "auth"}); // go to auth page
        // if the input fields exist in the dom, clear them.
        const customerInput = document.getElementById("customerno");
        const meterInput = document.getElementById("meterno");
        if (customerInput) customerInput.value = "";
        if (meterInput) meterInput.value = "";
    },

    /*
     * SET DATE TYPE to "global" || "individual"
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload
     */
    SET_DATE_TYPE: function(ctx, payload) {
        ctx.commit("SET_DATE_TYPE", payload);
    },

    /*
     * SET GLOBAL READING DATE
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload
     */
    SET_GLOBAL_READING_DATE: function(ctx, payload) {
        ctx.commit("SET_GLOBAL_READING_DATE", payload);
    },

    /*
     * SET METER READING REASON
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload
     */
    SET_READING_REASON: function(ctx, payload) {
        ctx.commit("SET_READING_REASON", payload);
    },

    /*
     * SET METER READING IMPLAUSIBLE REASON
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.meterNumber
     * @param {Number} payload.meterItemNumber
     * @param {String} payload.reason
     */
    SET_IMPLAUSIBLE_REASON: function(ctx, payload) {
        ctx.commit("SET_IMPLAUSIBLE_REASON", payload);
    },

    /*
     * SET METER READING IMPLAUSIBLE OTHER REASON
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.meterNumber
     * @param {Number} payload.meterItemNumber
     * @param {String} payload.reason
     */
    SET_IMPLAUSIBLE_REASON_OTHER: function(ctx, payload) {
        ctx.commit("SET_IMPLAUSIBLE_REASON_OTHER", payload);
    },

    /*
     * SET METER READING IMPLAUSIBLE OTHER REASON
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.meterNumber
     * @param {Number} payload.meterItemNumber
     */
    RESET_IMPLAUSIBLE_REASON: function(ctx, payload) {
        ctx.commit("RESET_IMPLAUSIBLE_REASON", payload);
    },

    /*
     * SET INDIVIDUAL READING DATE
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.meterNumber
     * @param {ISOString} payload.meterReadingDate
     */
    SET_INDIVIDUAL_READING_DATE: function(ctx, payload) {
        ctx.commit("SET_INDIVIDUAL_READING_DATE", payload);
    },

    /*
     * SET READING VALUE
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.meterNumber
     * @param {String} payload.meterItemNumber
     * @param {Number} payload.readingValue
     */
    SET_READING_VALUE: function(ctx, payload) {
        ctx.commit("SET_READING_VALUE", payload);
    },

    /*
     * SET READING VALUE
     * @param {Object} ctx - Vuex $store context
     */
    PROCEED_TO_CONFIRM: function(ctx) {
        ctx.commit("SET_SECTION_STATUS", {section: "meterReading", status: "ok"});
        ctx.commit("SET_SECTION_STATUS", {section: "confirm", status: ""});
        router.push({name: "confirm"});
    },

    /*
     * SUBMIT METER READINGS
     * @param {Object} ctx - Vuex $store context
     * @param {Object} payload - XHR post data
     */
    SUBMIT_READING: function(ctx, payload) {
        ctx.commit("SET_REQUESTING", true);
        ctx.commit("RESET_ERRORS");
        axios
            .post("/api/submit", payload)
            .then(response => {
                if (response.status === 200) {
                    ctx.commit("SET_REQUESTING", false);
                    if (response.data.error === 1) {
                        ctx.commit(
                            "SET_SERVER_ERROR",
                            "😓 Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten.<br />" +
                            "Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Kundenservice."
                        );
                    }
                    if (response.data.status === "ok" && response.data.print) {
                        console.log("server signals no error");
                        ctx.commit("SET_PRINT_FILE", response.data.print);
                        ctx.commit("SET_SECTION_STATUS", {section: "auth", status: ""});
                        ctx.commit("SET_SECTION_STATUS", {section: "meterReading", status: "locked"});
                        ctx.commit("SET_SECTION_STATUS", {section: "confirm", status: "locked"});
                        ctx.commit("SET_SECTION_STATUS", {section: "success", status: "ok"});
                        router.push({name: "success"});
                    }
                }
            })
            // something went wrong on Laravel's side.
            .catch(error => {
                ctx.commit("SET_REQUESTING", false);
                console.error(`${error.response.status} ${error.response.statusText}`);
                switch(error.response.status) {
                    case 429:
                        ctx.commit(
                            "SET_SERVER_ERROR",
                            "😡 Zuviele Anfragen.<br />" +
                            "Bitte versuchen Sie es in Kürze erneut."
                        );
                        break;
                    default: // mainly 500s. server can't reach DB, tables gone etc.
                        ctx.commit(
                            "SET_SERVER_ERROR",
                            "😓 Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten.<br />" +
                            "Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Kundenservice."
                        );
                        break;
                }
            });

    },



};

export default ACTIONS;
