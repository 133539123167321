<script>
/******************************************************************************
 * ERFOLGSMELDUNG
 *****************************************************************************/
import Icon from "@/components/Icon/Icon";
import PageSection from "@/components/PageSection/PageSection";
import NavRow from "@/components/NavRow/NavRow";
import {CLIENT_SHORT} from "@/config";
export default {
    components: { Icon, PageSection, NavRow, CLIENT_SHORT },
    data: function () {
        return {
            clientName: CLIENT_SHORT.replace(" ", "")
        }
    },
    computed: {
        printLink () { return `/pdf/${this.$store.getters.printFile}`; },
        fileName () {
            let today = new Date();
            let day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
            let date = day + "." + (today.getMonth() + 1) + "." + today.getFullYear();
            return this.clientName + "-Zählerstandsmeldung-" + date + ".pdf";
        }
    }
}
</script>

<template>
    <main>
        <div class="success-icon">
            <icon name="done" />
        </div>
        <h2>Alles erledigt!</h2>
        <p>Wir haben Ihre Zählerstände erhalten - <strong>vielen Dank</strong>!</p>
        <p>
            Um sich <strong>abzumelden</strong> schließen Sie einfach Ihr Browser Fenster oder benutzen
            Sie den Button oben rechts.
        </p>
        <p>
            Wenn Sie für eine weitere Kundennummer Zählerstände melden möchten,
            dann gehen Sie zur Anmeldung zurück und geben dort die neuen Daten ein.
        </p>
        <p>
            Wenn Sie wünschen können Sie sich für Ihre Unterlagen auch
            eine <strong>Druckansicht</strong> herunterladen.
        </p>
        <nav-row class="button-row">
            <template slot="right">
                <a
                    target="_blank"
                    :href="printLink"
                    :download="fileName">
                    Druckansicht
                    <icon name="print" />
                </a>
            </template>
        </nav-row>
    </main>
</template>

<style lang="scss" scoped>
    .success-icon {
        width: 128px;
        height: 128px;
        border: #{map-get($sizes, "base") * 0.3} solid palette("state", "success");
        margin: 0 auto;

        border-radius: 50%;

        > svg {
            display: block;

            width: 104px;
            height: 104px;
            margin: 12px auto;

            color: palette("state", "success");
        }
    }

    h2 {
        padding: 0 0 #{map-get($sizes, "base") * 0.6} 0;
        border-bottom: map-get($sizes, "border") solid map-get($client, "dividerBg");
        margin: #{map-get($sizes, "base") * 1} 0 #{map-get($sizes, "base") * 0.6} 0;

        //color: palette("state", "success");

        font-size: #{map-get($sizes, "base") * 1.5};
        font-weight: 500;
        text-align: center;
    }
</style>
