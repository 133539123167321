<script>
/******************************************************************************
 * Title Bar
 *****************************************************************************/
import Icon from "@/components/Icon/Icon";
import {CLIENT_FULL, APP_SHORT_NAME, CLIENT_URL} from "@/config";
import OfflineNotification from "./OfflineNotification";
export default {
    components: {
        Icon,
        OfflineNotification
    },
    data: function () {
        return {
            appName: APP_SHORT_NAME,
            clientFull: CLIENT_FULL,
            clientUrl: CLIENT_URL
        }
    },
    computed: {
        isLoggedIn () {
            const readingLocked = this.$store.getters.sections
                .find(sec => sec.name === "meterReading").status === "locked";
            const successOk = this.$store.getters.sections
                .find(sec => sec.name === "success").status === "ok";
            const customerNo = this.$store.getters.customerNo !== "";
            const meterNo = this.$store.getters.meterNo !== "";
            return !readingLocked || successOk || customerNo || meterNo;
        }
    },
    methods: {
        logout () { this.$store.dispatch("LOGOUT"); }
    }
}
</script>

<template>
    <div class="titlebar clearfix">
        <a :href="clientUrl">
            <img
                class="logo"
                src="@/theme/client/logo.svg"
                :alt="clientFull">
        </a>
        <h1>Stadtwerke Bamberg</h1>
        <offline-notification />
        <button
            v-if="isLoggedIn"
            class="logout"
            title="Abmelden"
            aria-label="Abmelden"
            @click="logout()">
            <icon name="logout" />
        </button>
    </div>
</template>

<style lang="scss" scoped>
    $innerHeight: #{map-get($sizes, "titleBarHeight") - 10px};
    $innerHeightDesktop: #{map-get($sizes, "titleBarHeight") + map-get($sizes, "desktopPadding") - 20px};

    .titlebar {
        display: flex;

        overflow: hidden;
        height: map-get($sizes, "titleBarHeight");
        padding: 0.5rem 1rem;

        background: map-get($client, "titleBarBg");
        color: map-get($client, "titleBarText");

        @include respond-to("large") {
            height: #{map-get($sizes, "titleBarHeight") + map-get($sizes, "desktopPadding")};
        }

        .logo {
            height: $innerHeight;

            @include respond-to("large") {
                height: $innerHeightDesktop;
                margin-top: 5px;
            }
        }

        > h1 {
            margin: 0 0 0 auto;

            color: #9c9c9c;

            font-size: map-get($sizes, "base");
            font-weight: bold;
            line-height: $innerHeight;

            @include respond-to("large") {
                line-height: 60px;
            }
        }

        .logout {
            align-self: center;

            padding: 2px;
            border: 2px solid map-get($client, "titleBarButtonBg");
            margin-left: 5px; // header is 40px high by default

            background-color: map-get($client, "titleBarButtonBg");
            color: map-get($client, "titleBarButtonText");

            text-align: center;

            transition:
                background-color map-get($animation-speeds, "fast") linear,
                color map-get($animation-speeds, "fast") linear;

            @include respond-to("small") {
                padding: 4px;
            }

            @include respond-to("large") {
                margin-left: 10px;
            }

            &:hover {
                background-color: transparent;
                color: map-get($client, "titleBarButtonBg");

                cursor: pointer;
            }

            > svg {
                width: 20px;
                height: 20px;

                @include respond-to("small") {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
</style>
