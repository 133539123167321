<script>
/******************************************************************************
 * SUMMARY Component
 * this component displays a label:value pair
 *****************************************************************************/
export default {
    props: {
        label: {type: String, default: ""},
        value: {type: String, required: true},
        lastReading: {type: String, default: ""},
        readingRange: {type: String, default: ""},
        implausibleReason: {type: String, default: ""},
        implausibleReasonOther: {type: String, default: ""}
    },
    computed: {
        lastReadingClass () {
            return this.lastReading.indexOf("✔") > -1 ? "valid" : "invalid";
        },
        readingRangeClass () {
            return this.readingRange.indexOf("✔") > -1 ? "valid" : "invalid";
        }
    }
}
</script>

<template>
    <ul class="clearfix">
        <li class="label">{{ label }}:</li>
        <li class="value">
            {{ value }}
            <div
                v-if="lastReading"
                class="explanation"
                :class="lastReadingClass">{{ lastReading }}</div>
            <div
                v-if="readingRange"
                class="explanation"
                :class="readingRangeClass">{{ readingRange }}</div>
            <div
                v-if="implausibleReason"
                class="explanation">{{ implausibleReason }}</div>
            <div
                v-if="implausibleReasonOther"
                class="explanation">{{ implausibleReasonOther }}</div>
        </li>
    </ul>
</template>

<style lang="scss" scoped>
    $dividerBg: lighten(map-get($client, "dividerBg"), 5%);

    .screen-section {
        margin: #{map-get($sizes, "base") * 0.6} 0;

        &:last-child {
            margin-bottom: 0;
        }

        @include respond-to("medium") {
            margin: #{map-get($sizes, "base") * 1.2} 0;
        }
    }

    ul {
        padding: 0 0 #{map-get($sizes, "base") * 0.3} 0;
        border-bottom: map-get($sizes, "border") solid $dividerBg;
        margin: #{map-get($sizes, "base") * 0.3} 0;

        list-style: none;

        @include respond-to("medium") {
            margin: #{map-get($sizes, "base") * 0.6} 0;

            li {
                float: left;
            }

            .label { width: 35%; }
            .value { width: 65%; }
        }
    }

    li {
        padding: #{map-get($sizes, "base") * 0.3} 0;
    }

    .explanation {
        font-size: 0.8em;

        &.invalid {
            color: palette("state", "warning");

            font-weight: 700;
        }
    }

    .label {
        font-weight: 400;
    }
</style>
