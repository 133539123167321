<script>
/******************************************************************************
 * FormRow Component
 *****************************************************************************/
export default {
    props: {
        refId: {type: String, default: ""},
        label: {type: String, default: ""}
    },
    computed: {
        renderInput () { return this.$slots.input; },
        renderError () { return this.$slots.error; },
        renderLabel () { return this.$slots.input && this.label; },
        renderText () { return this.$slots.text; }
    }
}
</script>

<template>
    <div class="form-row clearfix">
        <label
            v-if="renderLabel"
            class="form-label"
            :for="refId">{{ label }}:</label>
        <div
            v-if="renderInput"
            class="input"><slot name="input" /></div>
        <div
            v-if="renderError"
            class="error-msg"><slot name="error" /></div>
        <div
            v-if="renderText"
            class="text"><slot name="text" /></div>
    </div>
</template>

<style lang="scss" scoped>
    $rowHeight: #{map-get($sizes, "base") * 1.4 + 12px + 4px};

    .form-row {
        padding: 0 0 map-get($sizes, "base") 0;
        margin: 0 0 map-get($sizes, "base") 0;

        &:last-child {
            margin-bottom: 0;
        }

        &.error.error input[type=text] {
            border-color: palette("state", "error");
        }

        .form-label {
            display: block;

            margin-bottom: 1rem;

            font-weight: 400;

            @include respond-to("medium") {
                float: left;

                width: calc(35% - #{map-get($sizes, "base")});
                margin: 0 map-get($sizes, "base") 0 0;

                line-height: $rowHeight;
                text-align: right;
            }
        }

        &.error .error-msg {
            display: block;
        }
    }

    .input {
        input[type=text],
        input[type=number] {
            width: 100%;
            max-width: map-get($breakpoints, "small");
            padding: #{map-get($sizes, "base") * 0.5} #{map-get($sizes, "base") * 0.7};
            border: #{map-get($sizes, "border")} solid map-get($client, "inputBorder");

            background-color: map-get($client, "inputBg");
            color: map-get($client, "inputText");
            //border-radius: map-get($client, "borderRadius");
            //box-shadow:
            //    inset 2px 2px 0 map-get($client, "appBg"),
            //    inset -2px 2px 0 map-get($client, "appBg"),
            //    inset 2px -2px 0 map-get($client, "appBg"),
            //    inset -2px -2px 0 map-get($client, "appBg");

            font-size: map-get($sizes, "base");
            line-height: 1.4;

            transition:
                background-color map-get($animation-speeds, "fast") linear,
                border-color map-get($animation-speeds, "fast") linear,
                color map-get($animation-speeds, "fast") linear;

            &:focus {
                background-color: darken(map-get($client, "inputBg"), 10%);
                color: palette("grey", "black");
                outline: 0;
                border-color: darken(map-get($client, "inputBg"), 15%);
            }

            &::placeholder {
                color: palette("grey", "infra");
            }
        }

        @include respond-to("medium") {
            float: left;

            width: 65%;
        }
    }

    .error-msg {
        display: none;

        clear: both;

        padding: map-get($sizes, "base") 0 0 0;

        color: palette("state", "error");

        font-weight: 400;

        &::first-letter { font-size: 1.6em; }

        @include respond-to("medium") { text-align: center; }
    }

    .text {
        margin-top: #{map-get($sizes, "base") * 0.4};

        @include respond-to("medium") {
            float: right;

            width: 65%;
            margin-top: #{map-get($sizes, "base") * 0.8};
        }
    }
</style>
