<script>
/******************************************************************************
 * Offline Notification
 *****************************************************************************/
const EVENTS = ["online", "offline", "load"];
import Icon from "@/components/Icon/Icon";
export default {
    data: function () {
        return {
            isOnline: window.navigator.onLine || true
        }
    },
    components: {
        Icon
    },
    methods: {
        updateOnlineStatus: function() {
            if (typeof window.navigator.onLine === "undefined") {
                this.isOnline = true;
            } else {
                this.isOnline = window.navigator.onLine;
            }
        }
    },
    mounted() {
        EVENTS.forEach(event => window.addEventListener(event, this.updateOnlineStatus));
    },
    beforeDestroy() {
        EVENTS.forEach(event => window.removeEventListener(event, this.updateOnlineStatus));
    }
}
</script>

<template>
    <div
        class="offline"
        v-if="!isOnline">
        <icon
            class="icon"
            name="not_allowed"
            size="1" />
        <span class="text">Offline</span>
    </div>
</template>

<style lang="scss" scoped>
    .offline {
        display: flex;
        align-items: center;
        align-self: center;

        padding: 0.2rem;
        margin-left: 5px;

        background: palette("state", "error");
        color: palette("text", "bright");

        text-indent: -1000em;

        @include respond-to("small") {
            padding: #{map-get($sizes, "base") / 3};
            margin-left: 10px;

            text-indent: 0;

            .icon {
                margin-right: #{map-get($sizes, "base") / 3};
            }
        }

        @include respond-to("large") {
            padding: 6px map-get($sizes, "base");
        }
    }
</style>
