/******************************************************************************
 *
 * persistent state handler
 * state is persisted via sessionStorage
 *
 *****************************************************************************/
import pkg from "../../../package";
const sessionStorageKey = pkg.name;
import { defaultState } from "../store";

/*
 * extract Query Variable from URL
 */
const getQueryVariable = ( variable ) => {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for ( let i = 0 ; i < vars.length ; i++ ) {
        let pair = vars[i].split( "=" );
        if ( pair[0] === variable ){
            return pair[1];
        }
    }
    return false;
};


/*
 * clean URL and remove params without a page refresh
 * needs history.replaceState
 */
const cleanUrl = () => {
    const shortUrl = top.location.href.substring( 0, top.location.href.indexOf( "?" ) );
    if ( history.replaceState ) {
        history.replaceState( "string", document.title, shortUrl );
    }
};

/*
 * find out if sessionStorage is available and works ===========================
 * (without throwing exceptions)
 * @returns {Boolean}
 */
export const storageAvailable = () => {
    let storage = window.sessionStorage;
    let x = "__storage_test__";
    try {
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === "QuotaExceededError" ||
                // Firefox
                e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0
        );
    }
};

/*
 * get state from sessionStorage ===============================================
 * @returns {Object}
 */
export const getState = () => {
    const _body = document.querySelector("body");
    const customerNo = getQueryVariable("c");
    const meterNo = getQueryVariable("m");
    const authedState = window.authedState;
    // local storage not supported / not working
    if (!storageAvailable()) return defaultState();
    let state = JSON.parse(sessionStorage.getItem(sessionStorageKey));

    // key not found in local storage
    if (!state) {
        console.log("no persisted state found, using defaults.");
        state = defaultState();
    } else {
        console.log("fetched state from sessionStorage");
    }

    // reset errors on page load
    state.authError = "";
    state.serverError = "";
    state.requesting = false;

    // authed state comes from url hash => blade json
    if (authedState) {
        state.customerNo = authedState.customerNo;
        state.meterNo = authedState.meterNo;
        state.customerId = authedState.customerId;
        state.clientId = authedState.clientId;
        state.meters = authedState.meters;
        state.meterReadings = authedState.meterReadings;
        state.customerDetails = authedState.customerDetails;
        state.sections.find(sec => sec.name === "auth").status = "ok";
        state.sections.find(sec => sec.name === "meterReading").status = "";
        let hasDismountedItem = false;
        state.meterReadings.forEach((reading) => {
            if (reading.dismounted && reading.meterReadingDate) {
                const date = reading.meterReadingDate.split(".");
                reading.meterReadingDate = new Date(date[2], date[1] - 1, date[0], 12, 0).toISOString();
                hasDismountedItem = true;
            }
        });
        if (hasDismountedItem) state.dateType = "individual";
        persistState(state);
        cleanUrl();
        location.href = "/#/meterReading";
    }

    // check for body attributes (=URL params)
    if (meterNo && customerNo) {
        _body.setAttribute("data-proceed", "once");
        state = defaultState(); // reset back to defaultState
        state.customerNo = customerNo;
        state.meterNo = meterNo;
        cleanUrl()
    }

    return state;
};

/*
 * persist userSettings in localStorage ========================================
 * @param {Object} userSettings
 */
export const persistState = state => {
    // local storage not supported / not working
    if (!storageAvailable()) return false;
    return sessionStorage.setItem(sessionStorageKey, JSON.stringify(state));
};

/*
 * reset sessionStorage ========================================================
 * @param {Object} userSettings
 */
export const resetState = () => {
    // local storage not supported / not working
    if (!storageAvailable()) return false;
    return sessionStorage.removeItem(sessionStorageKey);
};
