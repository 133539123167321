<script>
/******************************************************************************
 * Global Reading Date Component
 * this component allows toggling between global and individual reading dates
 *****************************************************************************/
import FormRow from "@/components/FormRow/FormRow";
import RadioList from "@/components/RadioGroup/RadioGroup";
import Calendar from "@/components/Calendar/Calendar";
export default {
    components: {
        FormRow,
        RadioList,
        Calendar
    },
    computed: {
        radioSettings () {
            return [
                {
                    id: "readingDateSetting_individual",
                    name: "readingDateSetting",
                    label: "Individuell pro Zähler",
                    value: "individual",
                    checked: this.dateType === "individual"
                },
                {
                    id: "readingDateSetting_global",
                    name: "readingDateSetting",
                    label: "Global für alle Zähler",
                    value: "global",
                    checked: this.dateType === "global"
                }

            ];
        },
        dateType () { return this.$store.getters.dateType; },
        meters () {
            const meters = this.$store.getters.meters;
            if (meters.length === 0) {
                location.reload(); // meh, workaround for bug.
            }
            return this.$store.getters.meters;
        },
        initialGlobalDate () { return this.$store.getters.globalReadingDate; },
        dateLabel () { return this.meters.length > 1 ? "Globales Ablesedatum" : "Ablesedatum"; },
        containsDismountedItem () {
            let containsDismountedItem = false;
            this.meters.forEach(meter => {
                if (meter.dismounted) containsDismountedItem = true;
            });
            return containsDismountedItem;
        }
    },
    methods: {
        changeDateType (type) {
            return this.$store.dispatch("SET_DATE_TYPE", type);
        },
        changeGlobalDate (date) {
            return this.$store.dispatch(
                "SET_GLOBAL_READING_DATE",
                date.toISOString()
            );
        }
    }
}
</script>

<template>
    <div class="data-type-toggle">
        <form-row
            label="Ablesedatum"
            v-if="meters.length > 1 && !containsDismountedItem">
            <template slot="input">
                <radio-list
                    :radio-settings="radioSettings"
                    @change-radio-value="changeDateType($event)" />
            </template>
        </form-row>
        <form-row
            :label="dateLabel"
            class="global-date"
            v-if="dateType === 'global' && !containsDismountedItem">
            <template slot="input">
                <calendar
                    :initial-date="initialGlobalDate"
                    :days-min="21"
                    :days-max="0"
                    @change-date="changeGlobalDate($event)" />
            </template>
        </form-row>
    </div>
</template>

<style lang="scss" scoped>
    .data-type-toggle {
        border-bottom: map-get($sizes, "border") solid map-get($client, "dividerBg");

        .form-row {
            border-bottom-width: 0;

            &:first-child {
                padding-bottom: #{map-get($sizes, "base")};
                margin-bottom: 0;
            }
        }
    }
</style>
