<script>
/******************************************************************************
 * Calendar Component
 * opinionated vue-datepicker-local wrapper
 *****************************************************************************/
import VueDatepickerLocal from "vue-datepicker-local/dist/vue-datepicker-local";
export default {
    data() {
        return {
            time: this.initialDate,
            local: {
                dow: 1, // Montag!
                monthsHead: "Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember".split("_"), // months of head
                months: "Jan_Feb_Mär_Apr_Mai_Jun_Jul_Aug_Sep_Okt_Nov_Dez".split("_"),
                weeks: "Mo_Di_Mi_Do_Fr_Sa_So".split("_") // weeks,
            }
        }
    },
    props: {
        initialDate: {type: String, default: new Date().toISOString()},
        daysMin: {type: Number, default: 21},
        daysMax: {type: Number, default: 0}
    },
    components: {
        VueDatepickerLocal
    },
    computed: {
        range () {
            const minDate = new Date(new Date().setDate(new Date().getDate() - this.daysMin - 1));
            const maxDate = new Date(new Date().setDate(new Date().getDate() + this.daysMax));
            return [minDate, maxDate];
        }
    },
    methods: {
        disabledDate (time) {
            return time < this.range[0] || time > this.range[1];
        }
    },
    watch: {
        // whenever time changes, this function will run
        time: function () {
            return this.$emit("change-date", this.time);
        }
    }
}
</script>

<template>
    <vue-datepicker-local
        v-model="time"
        format="DD.MM.YYYY"
        :local="local"
        type="normal"
        input-class="ivu-datepicker"
        popup-class="ivu-popup"
        :disabled-date="disabledDate" />
</template>

<style lang="css">
    @import "~vue-datepicker-local/dist/vue-datepicker-local.css";
</style>
<style lang="scss">
    $box-shadow:
        inset 2px 2px 0 map-get($client, "appBg"),
        inset -2px 2px 0 map-get($client, "appBg"),
        inset 2px -2px 0 map-get($client, "appBg"),
        inset -2px -2px 0 map-get($client, "appBg");

    .input .datepicker {
        display: block;

        &::before {
            top: 50%;
            right: auto;
            left: #{map-get($sizes, "base") / 2};

            width: 24px;
            height: 24px;
            transform: translateY(-50%);

            background: url("./date.svg") 50% 50% no-repeat;
            background-size: cover;
        }

        .ivu-datepicker {
            width: 100%;
            max-width: map-get($breakpoints, "small");
            height: auto;
            padding:
                #{map-get($sizes, "base") * 0.5}
                #{map-get($sizes, "base") * 0.7}
                #{map-get($sizes, "base") * 0.5}
                #{map-get($sizes, "base") * 2.65};
            border: map-get($sizes, "border") solid map-get($client, "inputBorder");

            background-color: map-get($client, "inputBg");
            color: map-get($client, "inputText");
            //border-radius: map-get($client, "borderRadius");
            //box-shadow: $box-shadow;

            font-size: map-get($sizes, "base");
            line-height: 1.4;

            transition:
                background-color map-get($animation-speeds, "fast") linear,
                border-color map-get($animation-speeds, "fast") linear,
                color map-get($animation-speeds, "fast") linear;

            &:focus,
            &.focus {
                background-color: darken(map-get($client, "inputBg"), 10%);
                color: palette("grey", "black");
                outline: 0;
                border-color: darken(map-get($client, "inputBg"), 15%);
                box-shadow: $box-shadow;
            }
        }
    }

    .ivu-popup.ivu-popup {
        padding: 1rem;

        font-size: map-get($sizes, "base");

        .calendar-head .calendar-year-select,
        .calendar-head .calendar-month-select {
            padding: 0 #{map-get($sizes, "base") / 3};

            color: map-get($client, "calendarTitleColor");

            font-size: #{map-get($sizes, "base") * 1.2};
            font-weight: 500;
            line-height: #{map-get($sizes, "base") * 2};
        }

        .calendar-body {
            width: 100%;
            max-width: 300px;
            height: 300px;

            color: map-get($client, "calenderBodyColor");
        }

        .calendar-week {
            color: map-get($client, "calenderHeadColor");
        }

        .calendar-head .calendar-prev-year-btn,
        .calendar-head .calendar-next-year-btn {
            display: none;
        }

        .calendar-prev-month-btn,
        .calendar-next-month-btn {
            $size: #{map-get($sizes, "base") * 2};
            top: 50%;

            width: $size;
            height: $size;
            transform: translateY(-50%);

            background: map-get($client, "calenderButtonColor");

            font-size: #{map-get($sizes, "base") * 2};
            line-height: 24px;

            &:hover {
                color: palette("brand");
            }
        }

        .calendar-prev-month-btn {
            left: 0;
        }

        .calendar-next-month-btn {
            right: 0;
        }
    }
</style>
