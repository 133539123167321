<script>
/******************************************************************************
 * Reading Reason
 * this component allows selecting of the meter reading reason
 *****************************************************************************/
import FormRow from "@/components/FormRow/FormRow";
import DropDown from "@/components/DropDown/DropDown";
export default {
    data() {
        return {
            arrayOfObjects: [
                {name: "Jahresablesung", value: "Jahresablesung"},
                {name: "Zwischenablesung", value: "Zwischenablesung"},
                {name: "Preisänderung", value: "Preisänderung"},
                {name: "Vertragswechsel", value: "Vertragswechsel"},
            ],
            selectedValue: this.$store.getters.readingReason
        }
    },
    components: { FormRow, DropDown },
    methods: {
        methodToRunOnSelect(payload) {
            return this.$store.dispatch("SET_READING_REASON", payload.value);
        }
    }
}
</script>

<template>
    <form-row
        label="Ablesegrund">
        <template slot="input">
            <drop-down
                :options="arrayOfObjects"
                :selected-value="selectedValue"
                @updateOption="methodToRunOnSelect" />
        </template>
    </form-row>
</template>
