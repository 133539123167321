<script>
/******************************************************************************
 * APP ENTRY COMPONENT
 *****************************************************************************/
import Header from "@/components/Header/Header";
import RouterView from "@/components/RouterView/RouterView";
import Footer from "@/components/Footer/Footer";
export default {
    components: {
        "app-header": Header,
        RouterView,
        "app-footer": Footer
    }
};
</script>

<template>
    <div class="app">
        <app-header />
        <router-view />
        <app-footer />
    </div>
</template>

<style lang="scss">
    @import-normalize;

    html {
        height: 100%;

        font-size: 62.5%; // @ default 16px 1 rem => 10px
    }

    body {
        overflow-x: hidden;

        //height: 100%;
        margin: 0;

        background: map-get($client, "bodyBg");
        color: palette("text");

        font-family: map-get($fonts, "system");
        font-size: map-get($sizes, "base");
        font-weight: 400;
        line-height: 1.5;
        text-decoration-skip-ink: auto;

        @extend %scrollbar;
    }

    svg.icon {
        display: none;
    }

    .clearfix {
        @extend %clearfix;
    }

    * {
        box-sizing: border-box;
    }

    p { max-width: map-get($breakpoints, "medium"); }

    strong { font-weight: 500; }
</style>

<style lang="scss" scoped>
    .app {
        position: relative;

        max-width: map-get($breakpoints, "large");
        margin: 0 auto;

        background: map-get($client, "appBg");

        @include respond-to("large") {
            margin-bottom: map-get($sizes, "desktopMargin");
        }
    }
</style>
